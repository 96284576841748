import { useContext } from 'react';
import Image from '@next/image';
import classNames from 'classnames';

import { ConfigContext } from '@providers/ConfigProvider';
import { HamburgerContext } from '@providers/HamburgerProvider';
import { FilesContext } from '@providers/FilesProvider';
import Logo from '@templates/Logo';
import SearchBox from '@components/SearchBox';
import UserProfileButton from '@components/UserProfileButton';
import CompareButton from '@components/CompareButton';
import Typography from '@components/atoms/Typography';
import PhoneNumber from '@components/atoms/PhoneNumber';
import Cart from '@components/Cart';
import useMediaQuery from 'hooks/useMediaQuery';

import classes from './MainHeader.module.scss';
import HamburgerButton from './components/HamburgerButton';

export const SEARCH_WRAPPER_ID = 'search-wrapper';

type Props = {
  searchName?: string;
  hideMobileSearch?: boolean;
};

const MainHeader = ({ searchName, hideMobileSearch = false }: Props): JSX.Element => {
  const isTablet = useMediaQuery('small');
  const { contact } = useContext(ConfigContext);
  const { hamburgerSettings } = useContext(HamburgerContext);
  const { getShopImageConfigs } = useContext(FilesContext);
  const [mainPartner] = getShopImageConfigs(['main-partner']);

  return (
    <>
      <header className={classes.wrapper}>
        <div className={classes.leftSide}>
          {isTablet && hamburgerSettings ? (
            hamburgerSettings.logo
          ) : (
            <div className={classes.logoWrapper}>
              <Logo classes={{ link: classes.logoAnchor }} hasPreload withLink />
            </div>
          )}
          {mainPartner && (
            <div className={classes.mainPartnerLogo}>
              <Image
                objectFit="contain"
                alt="partner"
                resolveConfig={{ ...mainPartner, preset: 'svg' }}
                hasFade={false}
                hasLazyLoad={false}
                hasPreload
              />
            </div>
          )}
          <div className={classes.contactPhone}>
            <PhoneNumber
              tel={contact.landlinePhone}
              variant="landline"
              typographyVariant="body2"
              lineHeight="21px"
              withoutIcon
            />
            <Typography variant="caption1" lineHeight="13px">
              {contact.openingHours}
            </Typography>
          </div>
        </div>
        <div className={classes.content}>
          <div
            className={classNames(classes.searchWrapper, { [classes.withMainPartnerLogo]: mainPartner })}
            id={SEARCH_WRAPPER_ID}
          >
            <SearchBox searchName={searchName} />
          </div>
          <div className={classes.icons}>
            <CompareButton />
            <UserProfileButton />
            <Cart />
          </div>
        </div>
        <div className={classes.mobileActions}>
          <UserProfileButton />
          <Cart isMobile />
          <HamburgerButton />
        </div>
      </header>
      {!hideMobileSearch && (
        <div className={classes.mobileSearch}>
          <SearchBox searchName={searchName} />
        </div>
      )}
    </>
  );
};

export default MainHeader;
