import { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { HamburgerContext } from '@providers/HamburgerProvider';
import useBoolean from 'hooks/useBoolean';

import { Props } from './MainMenuMobile';

const MainMenuMobileView = dynamic(() => import('./MainMenuMobile'), { ssr: false });

const MainMenuMobile = (props: Props): JSX.Element => {
  const { isHamburgerMenuOpened } = useContext(HamburgerContext);
  const [loaded, { on: loadComponent }] = useBoolean();

  useEffect(() => {
    if (isHamburgerMenuOpened) {
      loadComponent();
    }
  }, [isHamburgerMenuOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return loaded ? <MainMenuMobileView {...props} /> : <></>;
};

export default MainMenuMobile;
