import { ReactNode, RefObject } from 'react';
import { InView } from 'react-intersection-observer';

type Props = {
  triggerOnce?: boolean;
  threshold?: number;
  children: (props: {
    inView: boolean;
    ref: RefObject<any> | ((node?: Element | null | undefined) => void); // eslint-disable-line @typescript-eslint/no-explicit-any
  }) => ReactNode;
  onChange?: (inView: boolean) => void;
};

const RenderInView = ({ triggerOnce = true, threshold, children, onChange }: Props): JSX.Element => (
  <InView threshold={threshold} triggerOnce={triggerOnce} onChange={onChange}>
    {(props) => children(props)}
  </InView>
);

export default RenderInView;
