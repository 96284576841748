import { useContext } from 'react';
import classNames from 'classnames';

import { HamburgerContext } from '@providers/HamburgerProvider';
import { useIntl } from 'utils/intl';

import messages from './HamburgerButton.messages';
import classes from './HamburgerButton.module.scss';

const HamburgerButton = (): JSX.Element => {
  const intl = useIntl();
  const { isHamburgerMenuOpened, setIsHamburgerMenuOpened, hamburgerSettings } = useContext(HamburgerContext);
  const handleClick = () => {
    if (typeof setIsHamburgerMenuOpened === 'function') {
      setIsHamburgerMenuOpened((prevState) => !prevState);
    }
  };

  return (
    <div className={classes.wrapper}>
      <button
        type="button"
        aria-label={intl.formatMessage(messages.buttonLabel)}
        className={classes.button}
        onClick={hamburgerSettings?.close || handleClick}
        data-eid="hamburger-menu"
      >
        <span className={classNames(classes.hamburgerMenu, { [classes.animate]: isHamburgerMenuOpened })} />
      </button>
    </div>
  );
};

export default HamburgerButton;
