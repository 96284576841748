import { createContext, ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import SetState from 'types/SetState';

type HamburgerSettings = {
  logo: ReactNode;
  close: () => void;
};

const HamburgerContext = createContext<{
  isHamburgerMenuOpened: boolean;
  hamburgerSettings?: HamburgerSettings;
  setIsHamburgerMenuOpened: SetState<boolean>;
  setHamburgerSettings: SetState<HamburgerSettings | undefined>;
}>({
  isHamburgerMenuOpened: false,
  setIsHamburgerMenuOpened: () => {},
  setHamburgerSettings: () => {},
});

const HamburgerProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [settings, setSettings] = useState<HamburgerSettings | undefined>();
  const router = useRouter();

  useEffect(() => {
    const resetHamburgerMenu = () => {
      setIsOpen(false);
      setSettings(undefined);
    };

    router.events.on('routeChangeStart', resetHamburgerMenu);
    return () => {
      router.events.off('routeChangeStart', resetHamburgerMenu);
    };
  }, [router]);

  return (
    <HamburgerContext.Provider
      value={{
        isHamburgerMenuOpened: isOpen,
        hamburgerSettings: settings,
        setIsHamburgerMenuOpened: setIsOpen,
        setHamburgerSettings: setSettings,
      }}
    >
      {children}
    </HamburgerContext.Provider>
  );
};

export default HamburgerProvider;
export { HamburgerContext };
