import { Fragment, useContext } from 'react';
import Head from 'next/head';
import { ParsedUrlQuery } from 'querystring';
import { UrlObject } from 'url';
import classNames from 'classnames';

import config from 'config';
import { ConfigContext } from '@providers/ConfigProvider';
import Typography from '@components/atoms/Typography';
import Container from '@components/atoms/Container';
import Link from '@components/atoms/Link';
import BreadcrumbType from 'types/Breadcrumb';
import { useIntl, FormattedMessage } from 'utils/intl';
import convertUrlObjectToString from 'utils/convertUrlObjectToString';
import getStructuredData from 'utils/getStructuredData';

import { getBreadcrumbStructuredData } from './Breadcrumb.utils';
import classes from './Breadcrumb.module.scss';
import messages from './Breadcrumb.messages';

const { routes } = config;

export type BreadcrumbVariant = 'custom' | Exclude<keyof typeof messages, 'showAll'>;

export type BreadcrumbLinkVariant = 'real' | 'pseudo';

type Props = {
  variant?: BreadcrumbVariant;
  breadcrumbs?: BreadcrumbType[];
  withoutBreadcrumbBorder?: boolean;
  linkVariant?: BreadcrumbLinkVariant;
};

const pathnames: Partial<Record<BreadcrumbVariant, UrlObject>> = {
  articles: { pathname: routes.articles.href },
  conferences: { pathname: routes.conferences.href },
};

const Breadcrumb = ({
  variant = 'custom',
  breadcrumbs: customBreadcrumbs = [],
  withoutBreadcrumbBorder = false,
  linkVariant = 'pseudo',
}: Props): JSX.Element => {
  const { appUrl } = useContext(ConfigContext);
  const intl = useIntl();

  const breadcrumbs =
    variant === 'custom'
      ? customBreadcrumbs
      : [{ text: intl.formatMessage(messages[variant]), path: pathnames[variant] }, ...customBreadcrumbs];

  return (
    <>
      <Head>{getStructuredData(getBreadcrumbStructuredData(breadcrumbs, appUrl))}</Head>
      <div
        className={classNames(classes.wrapper, { [classes.wrapperBorder]: !withoutBreadcrumbBorder })}
        data-nosnippet
      >
        <Container className={classes.container}>
          <>
            <Link href={routes.home.href} className={classes.breadcrumb} underline="hover">
              <Typography renderAs="span" variant="body2" align="center">
                <FormattedMessage {...messages.home} />
              </Typography>
            </Link>
          </>
          {breadcrumbs.map(({ path, resolvedPath, text, isText, selectOptions }, index) => {
            const isLastItem = index === breadcrumbs.length - 1;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                <span className={classes.arrow} />
                <div className={classNames(classes.breadcrumbWrapper, { [classes.last]: isLastItem })}>
                  {path && !isText && !isLastItem ? (
                    <Link
                      component={
                        linkVariant === 'pseudo' ||
                        path.pathname === routes.search.href ||
                        resolvedPath?.includes('search')
                          ? 'span'
                          : undefined
                      }
                      href={resolvedPath ? { pathname: resolvedPath } : convertUrlObjectToString(path)}
                      className={classes.breadcrumb}
                      underline="hover"
                    >
                      <Typography renderAs="span" variant="body2" align="center">
                        {text}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography
                      renderAs="span"
                      variant="body2"
                      className={classes.breadcrumb}
                      title={isLastItem ? text : undefined}
                      withEllipsis={isLastItem}
                    >
                      {text}
                    </Typography>
                  )}
                  {!!selectOptions?.options.length && path && (
                    <div className={classes.options}>
                      {(selectOptions.showAll
                        ? selectOptions.options
                        : selectOptions.options.slice(0, config.breadcrumbsMaxOptions - 1)
                      ).map((option) => (
                        <Link
                          key={option.value}
                          component={
                            linkVariant === 'pseudo' || option.resolvedValue?.includes('search') ? 'span' : undefined
                          }
                          href={
                            option.resolvedValue
                              ? { pathname: option.resolvedValue }
                              : convertUrlObjectToString({
                                  pathname: path.pathname,
                                  query: {
                                    ...((path.query || {}) as ParsedUrlQuery),
                                    [selectOptions.key]: option.value,
                                  },
                                })
                          }
                          className={classes.selectOption}
                          underline="hover"
                        >
                          <Typography renderAs="span" variant="body2" align="center">
                            {option.label}
                          </Typography>
                        </Link>
                      ))}
                      {!selectOptions.showAll && selectOptions.options.length >= config.breadcrumbsMaxOptions && (
                        <Link
                          component={
                            linkVariant === 'pseudo' && !breadcrumbs[index - 1]?.resolvedPath ? 'span' : undefined
                          }
                          href={breadcrumbs[index - 1]?.resolvedPath || breadcrumbs[index - 1]?.path || ''}
                          className={classNames(classes.selectOption, classes.selectAll)}
                          underline="hover"
                        >
                          <Typography renderAs="span" variant="body2" align="center">
                            <FormattedMessage {...messages.showMore} />
                          </Typography>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </Fragment>
            );
          })}
        </Container>
      </div>
    </>
  );
};

export default Breadcrumb;
