import { EventParams } from 'types/Analytics';
import { AddToCartResult } from 'graphql/mutations/orderItems/AddToCart';
import { getItems, getTotalPrice } from 'utils/analytics';

export const getAddToCartPayload = (
  products: AddToCartResult['addToCart'][0]['item'][]
): EventParams['add_to_cart'] => ({
  currency: products[0].pricePerUnit.currency,
  value: getTotalPrice(products),
  // TODO: add coupon field
  items: getItems(products),
});
