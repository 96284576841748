import { useState, useEffect, ReactNode, useContext } from 'react';

import { ProductInCart } from 'types/ProductInCart';
import { MappedCart } from 'types/MappedCart';
import { defaultCart } from 'consts/cart';
import {
  getCartRoute,
  clearCartRoute,
  addToCartRoute,
  removeFromCartRoute,
  updateOrderItemRoute,
  addComplementaryToCartRoute,
} from 'apiRoutes/carts';

import { ConfigContext } from '@providers/ConfigProvider';
import { UserContext } from '@providers/UserProvider';
import ProductAddedModal, { Props as ProductAddedModalProps } from '@components/ProductAddedModal';
import { GetResponse } from 'utils/apiRoute';
import getVariantThumbnail from 'utils/getVariantThumbnail';
import getNameByType from 'utils/getNameByType';
import { getAddToCartPayload } from 'utils/analytics/payload/addToCart';
import { getRemoveFromCartPayload } from 'utils/analytics/payload/removeFromCart';
import defer from 'utils/defer';
import map from 'utils/map';
import useTrack from 'hooks/useTrack';
import useFetch from 'hooks/useFetch';

import { CartContext } from './CartProvider.context';

const mapNewItem = (
  shopId: string,
  items?: GetResponse<typeof addToCartRoute>['items'],
  quantity?: number
): ProductAddedModalProps['products'] | undefined =>
  items
    ? items.map((item) => ({
        id: item.id,
        name: getNameByType(item.variant.names, 'CART', shopId),
        thumbnail: getVariantThumbnail(item.variant),
        netPrice: item.pricePerUnit,
        grossPrice: { amount: item.grossPricePerUnit, currency: item.pricePerUnit.currency },
        quantity: quantity || 1,
      }))
    : undefined;

const getMutableIds = (items: ProductInCart[], id: number, quantity?: number): number[] => {
  const item = items.find(({ id: itemId }) => itemId === id);
  return !item
    ? [id]
    : [
        id,
        ...map<ProductInCart, number>(
          items.filter(
            ({ baseVariantId, variant: itemVariant, quantity: itemQuantity }) =>
              itemVariant.id !== baseVariantId &&
              item.variant.id === baseVariantId &&
              (!quantity || itemQuantity > quantity)
          ),
          'id'
        ),
      ];
};

type Props = {
  children: ReactNode;
  cart?: MappedCart;
  withCheckoutComplementaryVariants?: boolean;
};

const CartProvider = ({
  children,
  cart: initialCart,
  withCheckoutComplementaryVariants = false,
}: Props): JSX.Element => {
  const { Provider } = CartContext;
  const [cart, setCart] = useState(initialCart || defaultCart);
  const [addedProduct, setAddedProduct] = useState<Omit<ProductAddedModalProps, 'onOpenChange'>>();
  const { isSessionRefreshed } = useContext(UserContext);
  const { shopId } = useContext(ConfigContext);
  const { trackEvent } = useTrack();

  const [fetchCart, { loading }] = useFetch(getCartRoute, { payload: { withCheckoutComplementaryVariants } });
  const [addToCart] = useFetch(addToCartRoute);
  const [addComplementaryToCart] = useFetch(addComplementaryToCartRoute);
  const [clearCart] = useFetch(clearCartRoute);
  const [removeFromCart] = useFetch(removeFromCartRoute);
  const [updateOrderItems] = useFetch(updateOrderItemRoute);

  const fetchAndSetCart = async () => {
    const { data } = await fetchCart();
    setCart(data?.cart || defaultCart);
  };

  useEffect(() => {
    if (!initialCart && isSessionRefreshed) {
      defer(fetchAndSetCart);
    }
  }, [isSessionRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModal = () => {
    setAddedProduct(undefined);
  };

  const onAddToCart = async (variantId: number, quantity: number, complementaryVariantIds: number[] = []) => {
    const { data: newProductData } = await addToCart({ variantId, quantity, complementaryVariantIds });

    const items = newProductData?.items;

    if (items) {
      trackEvent('e-commerce', 'add_to_cart', getAddToCartPayload(items));
    }

    const newProducts = mapNewItem(shopId, items, quantity);
    const { data } = await fetchCart();
    const newCart = data?.cart || defaultCart;
    setCart(newCart);
    if (newProducts) {
      setAddedProduct({
        products: newProducts,
        amounts: {
          quantity: newCart.quantity,
          netAmount: newCart.totalPrice,
          grossAmount: newCart.totalGrossPrice,
        },
      });
    }
  };

  const onAddComplementaryToCart = async (variantId: number, complementaryVariantId: number) => {
    const { data: newProductData } = await addComplementaryToCart({ variantId, complementaryVariantId });
    const items = newProductData?.items;

    if (items) {
      trackEvent('e-commerce', 'add_to_cart', getAddToCartPayload(items));
    }

    await fetchAndSetCart();
  };

  const onDeleteFromCart = async (id: number) => {
    const ids = getMutableIds(cart.items, id);
    const { data: deletedItemData } = await removeFromCart({ ids });
    const items = deletedItemData?.items;

    if (items?.length) {
      trackEvent('e-commerce', 'remove_from_cart', getRemoveFromCartPayload(items));
    }

    await fetchAndSetCart();
  };

  const onClearOrderItems = async () => {
    const { data: deletedItemData } = await clearCart();

    const items = deletedItemData?.items;

    if (items?.length) {
      trackEvent('e-commerce', 'remove_from_cart', getRemoveFromCartPayload(items));
    }
    await fetchAndSetCart();
  };

  const onUpdateOrderItem = async (id: number, quantity: number) => {
    const ids = getMutableIds(cart.items, id, quantity);
    await updateOrderItems({ ids, quantity });
    await fetchAndSetCart();
  };

  return (
    <Provider
      value={{
        cart,
        isLoading: loading || !isSessionRefreshed,
        onAddToCart,
        onAddComplementaryToCart,
        onDeleteFromCart,
        onUpdateOrderItem,
        onClearOrderItems,
      }}
    >
      {!!addedProduct?.products.length && <ProductAddedModal {...addedProduct} onOpenChange={closeModal} />}
      {children}
    </Provider>
  );
};

export default CartProvider;
