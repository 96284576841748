import { EventParams } from 'types/Analytics';
import { DeleteOrderItemsResult } from 'graphql/mutations/orderItems/DeleteOrderItems';
import { getItems, getTotalPrice } from 'utils/analytics';

export const getRemoveFromCartPayload = (
  products: DeleteOrderItemsResult['orderItems']['returning']
): EventParams['remove_from_cart'] => ({
  currency: products[0].pricePerUnit.currency,
  value: getTotalPrice(products),
  items: getItems(products),
});
