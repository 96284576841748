import { useContext } from 'react';

import config from 'config';
import CompareIcon from 'assets/icons/compare.svg';
import { CompareContext } from '@providers/CompareProvider';
import MenuButton from '@components/atoms/MenuButton';
import useIntl from 'hooks/useIntl';
import cssVar from 'utils/cssVar';

import messages from './CompareButton.messages';

const { routes } = config;

const CompareButton = (): JSX.Element => {
  const { count, isRefreshed } = useContext(CompareContext);
  const intl = useIntl();

  return (
    <MenuButton
      ariaLabel={intl.formatMessage(messages.compare)}
      href={{ pathname: routes.compare.href }}
      icon={CompareIcon}
      badgeColor={cssVar('activeColor')}
      badgeText={count}
      isLoading={!isRefreshed}
    />
  );
};

export default CompareButton;
