import Link, { Props as LinkProps } from '@components/atoms/Link';
import Typography from '@components/atoms/Typography';
import IconWithBadge from '@components/IconWithBadge';
import Message from 'types/Message';
import SVGComponent from 'types/SVGComponent';

import classes from './MenuButton.module.scss';

type Props = {
  href: LinkProps['href'];
  icon: SVGComponent;
  badgeColor?: string;
  badgeText?: string | number;
  text?: Message;
  isLoading?: boolean;
  ariaLabel: string;
};

const MenuButton = ({ href, icon, badgeColor, badgeText, text, ariaLabel, isLoading = false }: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <Link href={href} component="div" aria-label={ariaLabel} role="button" className={classes.link}>
      <IconWithBadge icon={icon} badgeColor={badgeColor} badgeText={badgeText} isLoading={isLoading} />
      {!!text && <Typography variant="body1">{text}</Typography>}
    </Link>
  </div>
);

export default MenuButton;
