import { ElementType, ForwardedRef, HTMLAttributes, ReactNode, forwardRef } from 'react';
import classNames from 'classnames';

import classes from './Container.module.scss';

type Props = {
  renderAs?: ElementType;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Container = forwardRef(
  ({ children, renderAs = 'div', className, ...rest }: Props, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const Component = renderAs;

    return (
      <Component {...rest} ref={ref} className={classNames(className, classes.container)}>
        {children}
      </Component>
    );
  }
);
export default Container;
