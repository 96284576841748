import config from 'config';
import Price from 'types/Price';
import { ProductInCart } from 'types/ProductInCart';
import { defaultPrice } from 'consts/price';
import Button from '@components/atoms/Button';
import Typography from '@components/atoms/Typography';
import Header from '@templates/MiniCart/components/Header';
import CartIcon from 'assets/icons/header-cart.svg';
import MenuButton from '@components/atoms/MenuButton';
import CompactProduct from '@components/CompactProduct';
import { getViewCartsPayload } from 'utils/analytics/payload/viewCart';
import { FormattedMessage, useIntl } from 'utils/intl';
import useTrack from 'hooks/useTrack';
import cssVar from 'utils/cssVar';

import messages from './Cart.messages';
import classes from './Cart.module.scss';

const { routes } = config;

type Amounts = {
  quantity: number;
  netAmount: Price;
  grossAmount: Price;
};

type Props = {
  isLoading: boolean;
  isMobile?: boolean;
  products?: ProductInCart[];
  amounts?: Amounts;
  onDeleteFromCart: (id: number) => void;
};

export const CART_ID = 'menu-cart';

const Cart = ({
  isMobile = false,
  isLoading = false,
  products = [],
  amounts = { quantity: 0, netAmount: defaultPrice, grossAmount: defaultPrice },
  onDeleteFromCart,
}: Props): JSX.Element => {
  const { trackEvent } = useTrack();
  const intl = useIntl();

  return (
    <div
      className={classes.container}
      id={!isMobile ? CART_ID : undefined}
      data-eid={CART_ID}
      onMouseEnter={() =>
        trackEvent('e-commerce', 'view_cart', getViewCartsPayload('small', amounts.grossAmount, products))
      }
    >
      <div className={classes.wrapper}>
        <Header quantity={amounts.quantity} netAmount={amounts.netAmount} grossAmount={amounts.grossAmount} />
        {products.length ? (
          <>
            <div className={classes.products}>
              {products
                .filter(({ quantity }) => quantity)
                .map((cartItem) => {
                  const { product } = cartItem.variant;

                  return (
                    <CompactProduct
                      displayMode="narrow"
                      href={
                        product.isConfigurable
                          ? {
                              pathname: routes.model.id.href,
                              query: {
                                [routes.model.id.slug]: product.id,
                                [routes.model.id.nameSlug]: product.urlSlug,
                                code: cartItem.configurationCode,
                              },
                            }
                          : {
                              pathname: routes.product.id.href,
                              query: {
                                [routes.product.id.nameSlug]: cartItem.variant.urlSlug,
                                [routes.product.id.slug]: cartItem.variantId,
                              },
                            }
                      }
                      key={cartItem.id}
                      productTypeId={cartItem.productTypeId}
                      name={cartItem.name}
                      thumbnail={cartItem.thumbnail}
                      netPrice={cartItem.pricePerUnit}
                      grossPrice={cartItem.grossPricePerUnit}
                      quantity={cartItem.quantity}
                      onDeleteProduct={() => onDeleteFromCart(cartItem.id)}
                    />
                  );
                })}
            </div>
            <Button
              size="lg"
              component="button"
              href={routes.checkout.cart.href}
              dataEid="go-to-cart-button"
              color="primary"
              className={classes.button}
              wide
            >
              <FormattedMessage {...messages.goTo} />
            </Button>
          </>
        ) : (
          <div className={classes.emptyState}>
            <Typography variant="h4" renderAs="div" align="center">
              <FormattedMessage {...messages.emptyCart} />
            </Typography>
            <Typography variant="caption2" align="center" renderAs="div" className={classes.info}>
              <FormattedMessage {...messages.emptyCartInfo} />
            </Typography>
          </div>
        )}
      </div>
      <MenuButton
        href={routes.checkout.cart.href}
        badgeColor={cssVar('shopColor')}
        icon={CartIcon}
        badgeText={amounts.quantity}
        isLoading={isLoading}
        ariaLabel={intl.formatMessage(messages.goTo)}
      />
    </div>
  );
};

export default Cart;
