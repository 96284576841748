import { ReactNode } from 'react';
import classNames from 'classnames';

import SVGComponent from 'types/SVGComponent';
import Loader from '@components/atoms/Loader';
import createStyleVariables from 'utils/createStyleVariables';

import classes from './IconWithBadge.module.scss';

type Props = {
  icon: SVGComponent;
  badgeColor?: string;
  badgeText?: ReactNode;
  isLoading?: boolean;
};

const IconWithBadge = ({ icon: Icon, badgeColor, badgeText, isLoading = false }: Props): JSX.Element => (
  <div className={classes.container} style={createStyleVariables({ badgeColor })}>
    <div className={classes.wrapper}>
      <Icon className={classes.icon} />
      {!!badgeText && !isLoading && <p className={classNames(classes.sticker, classes.label)}>{badgeText}</p>}
      {isLoading && (
        <p className={classNames(classes.sticker, classes.loader)}>
          <Loader size={20} isDark />
        </p>
      )}
    </div>
  </div>
);

export default IconWithBadge;
