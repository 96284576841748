import Price from 'types/Price';
import AmountFormatter from '@misc/AmountFormatter';
import { FormattedMessage } from 'utils/intl';

import classes from './Header.module.scss';
import messages from './Header.messages';

type Props = {
  quantity: number;
  netAmount: Price;
  grossAmount: Price;
};

const Header = ({ quantity, netAmount, grossAmount }: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <div className={classes.name}>
      <FormattedMessage {...messages.cart} />
      <div className={classes.quantity}>
        <FormattedMessage values={{ quantity }} {...messages.quantity} />
      </div>
    </div>
    {!!quantity && (
      <div className={classes.amount}>
        <p className={classes.net}>
          <FormattedMessage values={{ value: <AmountFormatter price={netAmount} /> }} {...messages.net} />
        </p>
        <p className={classes.gross}>
          <FormattedMessage values={{ value: <AmountFormatter price={grossAmount} /> }} {...messages.gross} />
        </p>
      </div>
    )}
  </div>
);

export default Header;
