import { useContext } from 'react';

import config from 'config';
import AccountIcon from 'assets/icons/account.svg';
import LoggedAcountIcon from 'assets/icons/logged-account.svg';
import { UserContext } from '@providers/UserProvider';
import MenuButton from '@components/atoms/MenuButton';
import { FormattedMessage, useIntl } from 'utils/intl';

import messages from './UserProfileButton.messages';

const { routes } = config;

type Props = {
  withText?: boolean;
};

const UserProfileButton = ({ withText }: Props): JSX.Element => {
  const { isLoggedIn } = useContext(UserContext);
  const intl = useIntl();

  const menuButtonProps = isLoggedIn()
    ? {
        href: routes.myAccount.href,
        icon: LoggedAcountIcon,
        text: withText ? <FormattedMessage {...messages.loggedIn} /> : undefined,
        ariaLabel: intl.formatMessage(messages.loggedIn),
      }
    : {
        href: routes.login.href,
        icon: AccountIcon,
        text: withText ? <FormattedMessage {...messages.loggedOut} /> : undefined,
        ariaLabel: intl.formatMessage(messages.loggedOut),
      };
  return <MenuButton {...menuButtonProps} />;
};

export default UserProfileButton;
