import config from 'config';
import { appContainerId } from 'consts/app';
import CartProvider from '@providers/CartProvider';
import HamburgerProvider from '@providers/HamburgerProvider';
import Footer from '@components/Footer';
import MainHeader from '@components/MainHeader';
import RenderInView from '@templates/RenderInView';
import { MainMenu, MainMenuMobile } from '@templates/MainMenu';
import Breadcrumb from '@templates/Breadcrumb';
import Container from '@components/atoms/Container';

import classes from './PageLayout.module.scss';
import Props from './PageLayout.types';

const PageLayout = ({
  mainMenu,
  children,
  showBreadcrumb,
  breadcrumbVariant,
  breadcrumbs,
  searchName,
  footerLogos,
  cart,
  hideMobileSearch = false,
  withoutBreadcrumbBorder = false,
  breadcrumbLinkVariant = 'pseudo',
  withCheckoutComplementaryVariants = false,
}: Props): JSX.Element => (
  <CartProvider cart={cart} withCheckoutComplementaryVariants={withCheckoutComplementaryVariants}>
    <div id={appContainerId} className={classes.wrapper}>
      <HamburgerProvider>
        <Container data-nosnippet>
          <MainHeader searchName={searchName} hideMobileSearch={hideMobileSearch} />
        </Container>
        <Container className={classes.menuContainer}>
          {mainMenu && <MainMenu mainMenu={mainMenu} />}
          {mainMenu && <MainMenuMobile mainMenu={mainMenu} />}
          {showBreadcrumb && (
            <Breadcrumb
              variant={breadcrumbVariant}
              linkVariant={breadcrumbLinkVariant}
              breadcrumbs={breadcrumbs}
              withoutBreadcrumbBorder={withoutBreadcrumbBorder}
            />
          )}
        </Container>
      </HamburgerProvider>
      <div className={classes.overlay} id={config.OVERLAY_ID} />
      <Container renderAs="main">{children}</Container>
      <RenderInView>
        {({ ref, inView }) => (
          <>
            <div ref={ref} className={classes.footerOverscan} />
            <Container renderAs="footer">{inView && <Footer footerLogos={footerLogos} />}</Container>
          </>
        )}
      </RenderInView>
    </div>
  </CartProvider>
);

export default PageLayout;
