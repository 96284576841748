import { BreadcrumbList, WithContext } from 'schema-dts';

import Breadcrumb from 'types/Breadcrumb';
import convertUrlObjectToString from 'utils/convertUrlObjectToString';

export const getBreadcrumbStructuredData = (breadcrumbs: Breadcrumb[], appUrl: string): WithContext<BreadcrumbList> => {
  const structuredData: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, index) => {
      const subPath = breadcrumb.resolvedPath || convertUrlObjectToString(breadcrumb.path);
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: `${breadcrumb.text}`,
        item: subPath ? `${appUrl}${subPath}` : undefined,
      };
    }),
  };
  return structuredData;
};
