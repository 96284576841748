import { useRef } from 'react';
import { Menu } from 'types/models/Menu';
import Container from '@components/atoms/Container';

import MenuItem from '../components/MenuItem';
import classes from './MainMenu.module.scss';

type Props = {
  mainMenu: Menu[];
};

const MainMenu = ({ mainMenu }: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={classes.wrapper} ref={ref} data-nosnippet>
      <Container className={classes.container} renderAs="nav">
        <div className={classes.menuItemsWrapper}>
          <div className={classes.menuItems}>
            {mainMenu.map((item) => (
              <MenuItem key={item.id} {...item} />
            ))}
            <div data-eid="menu-popup-wrapper" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainMenu;
